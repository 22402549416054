<template>
<div>
<div class='panel panel-default'>
    <div class='panel-heading'></div>
    <div class='row panel-body'>
        <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
            <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.timestamp_from.val" />
        </div>
        <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
            <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.timestamp_to.val" />
        </div>
    </div>
</div>
</div>
</template>
<script>
import { store } from '@/store/Report.store.js';
import DatePicker from "@/components/utils/DatePicker";

export default {
    name: "Options",
    components: { DatePicker},
    data() {
        return {
            state: store.state,
        }
    },
}
</script>

<style scoped>

</style>