<template>
    <div>
        <h1>Automated Task Logs</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js';
import Options from './automation_log_opt';
import Results from './utils/Results';
import DateHelpers from "@/store/DateHelpers";

export default {
    name: "automation_log",
    components: {
        Options,
        Results,
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                timestamp_from: {val: DateHelpers.getToday(), qsField: '[dates][timestamp_from]'},
                timestamp_to: {val: DateHelpers.getTomorrow(), qsField: '[dates][timestamp_to]'},
            },
            groupByFields: {},
            dataFields: {},
        }
    },
    methods: {
        runReport: function() {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Automation Log']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    }
}
</script>

<style scoped>

</style>